import React, { useRef, useEffect, useState } from "react";
import { InView, useInView } from "react-intersection-observer";
import "intersection-observer";
import "./../assets/css/animation.css";

const AnimationWrapper = ({
  children,
  className,
  test,
  initialInView,
  delay,
}) => {
  const [ref, inView] = new useInView({
    triggerOnce: true,
    initialInView: true,
    threshold: 1,
    onChange: () => {
     // console.log(window.innerWidth >= 786 ? 1 : 0.8);
    },
  });

  return (
    <div ref={ref}>
      {React.Children.map(children, (child, index) => (
        <div className={inView ? className : "hidden"} key={index}>
          {child}
        </div>
      ))}
    </div>
  );
};

export default AnimationWrapper;
